<template>
  <div class="relative">
    <div ref="emblaRef" class="embla overflow-hidden">
      <div class="embla__container flex" :class="ui.wrapper">
        <div
          v-for="(slide, index) in items"
          :key="index"
          class="embla__slide relative grow-0 shrink-0"
          :class="ui.slide"
        >
          <slot name="items" :item="slide" :index="index" />
        </div>
      </div>
    </div>
    <slot name="indicators" :api="emblaApi" :active="active" />
  </div>
</template>

<script setup lang="ts">
import emblaCarouselVue from 'embla-carousel-vue'
import Autoplay from 'embla-carousel-autoplay'

const props = withDefaults(
  defineProps<{
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    items: { [key: string]: any }[]
    loop?: boolean
    ui?: {
      slide?: string
      wrapper?: string
    }
  }>(),
  {
    loop: false,
    ui: () => ({
      slide: 'basis-full',
      wrapper: '',
    }),
  }
)
const [emblaRef, emblaApi] = emblaCarouselVue({ loop: props.loop }, [Autoplay({ delay: 10000 })])

const active: Ref = ref(0)

watchEffect(() => emblaApi.value?.on('select', (api) => (active.value = api.selectedScrollSnap())))

const setActive = (value: string | number, key: string = 'id', jump: boolean = true) => {
  const index = props.items.findIndex((item) => item[key] == value)
  emblaApi.value?.scrollTo(index, jump)
}

defineExpose({
  setActive,
})
</script>
